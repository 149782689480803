const { useEffect } = require("react");

const useHSScript = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hs-scripts.com/19540164.js";
    script.async = true; 
    script.defer = true;
    script.id = "hs-script-loader"; 
    script.type = 'text/javascript';

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default useHSScript;